import React, { useState, useEffect } from "react";

//import { Routes, Route } from "react-router-dom";
//import { Link } from 'react-router-dom';
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";
import check from "../../assets/Resume/check25.png";



const Resume = (props) => {
  /* STATES */
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  /* REUSABLE MINOR COMPONENTS */
  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? props.heading : ""}</span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + " - " + props.toDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-sub-heading-project">
          <span>{props.projectLink ? props.projectLink : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
      </div>
    );
  };

  /* STATIC RESUME DATA FOR THE LABELS*/
  const resumeBullets = [
    { label: "Education", logoSrc: "education.svg" },
    { label: "Work History", logoSrc: "work-history.svg" },
    { label: "Programming Skills", logoSrc: "programming-skills.svg" },
    { label: "Projects", logoSrc: "projects.svg" },
    { label: "Interests", logoSrc: "interests.svg" },
  ];

  //here we have
  const programmingSkillsDetails = [
    { skill: "Manual Tests", ratingPercentage: 90 },
    { skill: "Automation Test", ratingPercentage: 30 },
    { skill: "JMeter", ratingPercentage: 30 },
    { skill: "Data Bases", ratingPercentage: 50 },
    { skill: "Postman API's", ratingPercentage: 30 },
    { skill: "Jenkins", ratingPercentage: 40 },
    { skill: "Jira", ratingPercentage: 80 },
    { skill: "Confluence", ratingPercentage: 70 },
    { skill: "React JS", ratingPercentage: 50 },
    { skill: "JavaScript", ratingPercentage: 50 },
    { skill: "Java", ratingPercentage: 40 },
    { skill: "HTML/CSS", ratingPercentage: 80 },
  ];

  const projectsDetails = [
    {
      title: "What's Software Tests.",
      duration: { fromDate: "Present", toDate: "..." },
      description:
        "Informative Website on the Principles and Fundamentals of Software Testing",
      subHeading: "Technologies used: React JS, own API, CSS",
    },
    {
      title: "Personal Portfolio Website",
      duration: { fromDate: "2022", toDate: "2022" },
      description:
        "My Personal CV website to present all my details, work experience and projects in one place.",
      subHeading: "Technologies used: React JS, CSS",
    },
    // {
    //   title: "Manage Departments and Employees",
    //   duration: { fromDate: "2022", toDate: "2022" },
    //   description:
    //     "A simple application designed to create and managing Departments and Employees in an Organization",
    //   subHeading:
    //     "Technologies Used:  JAVA, SQL databases.",
    // },
    {
      title: "Restaurant Orders Website" , 
      duration: { fromDate: "2021", toDate: "2022" },
      description:"An application designed to sell meals online.",
      subHeading: "Technologies used: React JS, own API, CSS.",
      projectLink: <a href="http://www.workinprogress.pt/react/" target="blank">go to project...</a>,
    },
    {
      title: "Fly Swatter Game ",
      duration: { fromDate: "2020", toDate: "2021" },
      description:
        "Online game to kill fly's in short time.",
      subHeading:
        "Technologies used: HTML, CSS, Javascript.",
        projectLink: <a href="http://www.workinprogress.pt/flygame/" target="blank">go to project...</a>,
    },
  ];

  const resumeDetails = [
    <div className="resume-screen-container" key="education">
      <ResumeHeading
        heading={"DUAL"}
        subHeading={"Certified in Java for Web"}
        fromDate={"2022"}
        toDate={"2022"}
      />
      <ResumeHeading
        heading={"RUMOS"}
        subHeading={"Certified in Front End Web Developer (Js, React)"}
        fromDate={"2021"}
        toDate={"2022"}
      />
      <ResumeHeading
        heading={"UDEMY"}
        subHeading={"Automation Software Tests - JAVA"}
        fromDate={"2021"}
        toDate={"2021"}
      />

      <ResumeHeading
        heading={"UDEMY"}
        subHeading={"Full Stack Web Developer"}
        fromDate={"2020"}
        toDate={"2021"}
      />
      <ResumeHeading
        heading={"ISPGAYA "}
        subHeading={"IT Management Degree"}
        fromDate={"2006"}
        toDate={"2009"}
      />
    </div>,

    /* WORK EXPERIENCE */
    <div className="resume-screen-container" key="work-experience">
      <div className="experience-container">
        <ResumeHeading
          heading={"MSG LIfe Iberia - QA Software Tester"}
          fromDate={"2022"}
          toDate={"Present"}
        />
        <div className="experience-description">
          {/* <span className="resume-description-text">
            Currently working as QA Software
          </span> */}
        </div>
        <div className="experience-description">
          <span className="resume-description-text">
           - Design test cases/user scenarios and perform manual and automation tests, managing and reporting bug tickets, 
             I start learning designing Automation Tests
          </span>
          <br />
          <span className="resume-description-text">
            - Tools used: Jira, Confluence, VS Code, SOAP UI, XML Log files, MySql DB, JMeter, DevTools Browser
          </span>
          <br />
         
          <br />
        </div>
        <ResumeHeading
          heading={"XD Software - QA Software Tester"}
          fromDate={"2021"}
          toDate={"2022"}
        />
        <div className="experience-description">
          <span className="resume-description-text">
          - Design test cases and perform manual tests, managing and reporting bug tickets.
          </span>
          <br />
          <span className="resume-description-text">
          - Tools used: Own App managing bug tickets, VS Code, XML Log files, MySql DB, DevTools Browser.
          </span>
          <br />
          
          <br />
        </div>

        <ResumeHeading
          heading={"Solius - Developer, Business Analyst, QA SW Tester"}
          fromDate={"2009"}
          toDate={"2021"}
        />
        <div className="experience-description">
          <span className="resume-description-text">
           - Design test cases and perform manual tests, manage and reporting bug tickets, Manage 
           the new features in the scope of business. Programming and Development simple websites.
          </span>
          <br />
          <span className="resume-description-text">
            - Tools used: Trello, VS Code, XML Log files, MySql DB, DevTools Browser.
          </span>
          <br />
         
          <br />
        </div>  


      </div>
    </div>,

    /* PROGRAMMING SKILLS */
    <div
      className="resume-screen-container programming-skills-container"
      key="programming-skills"
    >
      {programmingSkillsDetails.map((skill, index) => (
        <div className="skill-parent" key={index}>
          <div className="heading-bullet"></div>
          <span>{skill.skill}</span>
          <div className="skill-percentage">
            <div
              style={{ width: skill.ratingPercentage + "%" }}
              className="active-percentage-bar"
            ></div>
          </div>
        </div>
      ))}
    </div>,

    /* PROJECTS */
    <div className="resume-screen-container" key="projects">
      {projectsDetails.map((projectsDetails, index) => (
        <ResumeHeading
          key={index}
          heading={projectsDetails.title}
          subHeading={projectsDetails.subHeading}
          projectLink={projectsDetails.projectLink}
          description={projectsDetails.description}
          fromDate={projectsDetails.duration.fromDate}
          toDate={projectsDetails.duration.toDate}
        />
      ))}
    </div>,

    /* Interests */
    <div className="resume-screen-container" key="interests">
      <ResumeHeading
        heading="Travel"
        description="My great passion is traveling and getting to know a bit of the customs and traditions of each country that i visit. "
      />
      <ResumeHeading
        heading="Learning Programming skills"
        description="I like to study programming languages and understand the logical concept of each one and do small personal projects.."
      />
      <ResumeHeading
        heading="Long walks"
        description="I love to play and go for long walks with my dogs on the beach or in a restrict dogs park."
      />
    </div>,
  ];

  const handleCarousal = (index) => {
    let offsetHeight = 360;

    let newCarousalOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
        <img
          className="bullet-logo"
          //src={require(`../../assets/Resume/${bullet.logoSrc}`).default}
          src={check}
          alt="x"
          
        />
        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  };

  const getResumeScreens = () => {
    return (
      <div
        style={carousalOffsetStyle.style}
        className="resume-details-carousal"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div className="resume-container screen-container " id={props.id || ""}>
      <div className="resume-content">
        <ScreenHeading title={"Resume"} subHeading={"My formal Bio Details"} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreens()}</div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
