/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./Footer.css";
import shape from "../../../../src/img/Testimonial/shape-bg.png";

export default function footer() {
  return (
    <div className="footer-container">
      <div className="footer-parent">
        
        <img src={shape} alt="image not responding" />

      </div>
    </div>
  );
}
