import React, { useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./AboutMe.css";

export default function AboutMe(props) {
  
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const SCREEN_CONSTSANTS = {
    description:
      "I'm a QA Software Tester with background knowledge of some of the main programming languages, along with a knack of working with data bases and API's. Strong Professional with a commitment mindset with the team, willing to be an asset for the organization. Knowledge and experience working with software delivery lifecycle in agile environment.",
    highlights: {
      bullets: [
        "Design Test Cases / User Scenarios",
        "Test documentation",
        "Carrying out Manual/Automation Testing",
        "Spotting glitches and bugs in the software",
        "Write efficiently bug reports",
        "Managing Database / API's",
        "Perform a several types of tests: ",
      ],
      typeTest: "End-to-End, Acceptance, Functional, Regression, Performance, Integration and Smoke tests",
      heading: "Here are a Few Highlights:",
    },
  };
  const renderHighlight = () => {
    return SCREEN_CONSTSANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ));
  };

  return (
    <div className="about-me-container screen-container" id={props.id || ""}>
      <div className="about-me-parent">
        <ScreenHeading title={"About Me"} subHeading={" Who am I!"} />
        <div className="about-me-card">
          <div className="about-me-profile"></div>
          <div className="about-me-details">
            <span className="about-me-description">
              {SCREEN_CONSTSANTS.description}
            </span>
            <div className="about-me-highlights">
              <div className="highlight-heading">
                <span>{SCREEN_CONSTSANTS.highlights.heading}</span>
              </div>
              {renderHighlight()}
              <p className="highlight-options-tests">{SCREEN_CONSTSANTS.highlights.typeTest}</p>
            </div>
            <div className="about-me-options">
              <button className="btn primary-btn"
              onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
              > Contacts </button>
              <a href="CV_Belmiro_Silva.pdf" download="CV Belmiro Silva.pdf">
                <button className="btn highlighted-btn">Get Resume</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
